<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper">
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm3 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            v-model="sSearch"
            append-icon="mdi-magnify"
            :placeholder="'Búsqueda general...'"
          >
          </v-text-field>
        </v-flex>
        <v-flex
          v-if="aAllows.includes('PurchaseOrder')"
          xs12
          sm3
          style="margin-right: 1%"
        >
          <p class="FieldLabel"></p>
          <v-select
            v-model="selectRFM"
            item-text="state"
            item-value="value"
            :items="itemRFM"
            class="custom-placeholder"
            background-color="#FAFAFA"
            dense
            :placeholder="'Solicitudes'"
            outlined
            color="primary"
          >
          </v-select>
        </v-flex>

        <v-spacer></v-spacer>
        <v-flex xs12 sm2 class="text-right">
          <p class="FieldLabel"></p>
          <v-btn
            v-if="
              aAllows.includes('RequestForMaterials') ||
              aAllows.includes('Administrator')
            "
            color="primary"
            style="
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 8px;
              height: 40px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              margin-right: -24px;
            "
            @click="dialog = true"
            >Nueva solicitud
          </v-btn>
        </v-flex>
        <!-- End search text field -->
      </v-layout>
    </v-card>

    <v-dialog
      class="modal-nueva-soli"
      persistent
      v-model="dialog"
      width="1000"
      style="z-index: 9999999999"
    >
      <v-card
        persisten
        style="padding-top: 15px; padding-bottom: 15px; border-radius: 15px"
      >
        <v-icon
          @click="dialog = false"
          color="#000000"
          style="float: right; padding: 5px; margin-right: 10px"
          >mdi-close
        </v-icon>
        <v-card-title
          class="headline justify-center"
          style="text-align: center"
        >
          Nueva solicitud de material
        </v-card-title>
        <hr style="height: 1px; background-color: #ffce00; border: none" />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="proyecto"
                class="text-Poppins"
                item-text="sName"
                item-value="sProjectId"
                :items="itemsProyecto"
                label="Proyecto"
                style="margin-bottom: -10px"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Concepto solicitado
                        </th>
                        <th class="text-center">Cantidad</th>
                        <th class="text-center">Unidad de medida</th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in itemsMateriales" :key="index">
                        <td>{{ item.sName }}</td>
                        <td>{{ item.dQuantity }}</td>
                        <td>{{ item.unidadMedidaMaterialText }}</td>
                        <td>
                          <v-icon color="red" @click="deleteItemMaterial(index)"
                            >mdi-close</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                    <br>
                    <tfoot>
                      <tr>
                        <td>
                          <v-text-field
                            v-model="nombreMaterial"
                            class="input-nueva-soli"
                            outlined
                            dense
                            placeholder="Concepto"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="cantidadMaterial"
                            class="input-nueva-soli"
                            outlined
                            dense
                            @keypress="isNumber($event)"
                            @change="notCero(cantidadMaterial)"
                            placeholder="Cantidad"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-select
                            v-model="unidadMedidaMaterial"
                            item-text="sName"
                            item-value="sMeasureUnitId"
                            :items="itemsUnits"
                            class="input-nueva-soli"
                            background-color="#FAFAFA"
                            dense
                            outlined
                            @change="changeUnidadMedidaMaterial"
                            color="primary"
                            placeholder="Unidad de medida"
                          >
                          </v-select>
                        </td>
                        <td>
                          <v-btn
                            style="
                              background-color: #ffce00;
                              font-family: 'Poppins', sans-serif;
                              font-size: 12px;
                              font-weight: 600;
                              border-radius: 10px;
                            "
                            :disabled="!validateAnadir"
                            @click="setMaterial"
                            >Añadir</v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <hr
                    style="
                      width: 97%;
                      border: 1px solid #fafafa;
                      margin-top: 50px;
                    "
                  />
                </div>
                <!-- <div>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="nombreMaterial"
                            class="input-nueva-soli"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="cantidadMaterial"
                            class="input-nueva-soli"
                            outlined
                            dense
                            type="number"
                            min="0"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-select
                            v-model="unidadMedidaMaterial"
                            item-text="sName"
                            item-value="sMeasureUnitId"
                            :items="itemsUnits"
                            class="custom-placeholder"
                            background-color="#FAFAFA"
                            dense
                            outlined
                            @change="changeUnidadMedidaMaterial"
                            color="primary"
                          >
                          </v-select>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-btn
                            style="
                              background-color: #ffce00;
                              font-family: 'Poppins', sans-serif;
                              font-size: 12px;
                              font-weight: 600;
                              border-radius: 10px;
                            "
                            :disabled="!validateAnadir"
                            @click="setMaterial"
                            >Añadir</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div> -->
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-layout justify-center>
          <v-card-actions justify-center>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    @click="dialog = false"
                    x-large
                    color="black"
                    width="175"
                    outlined
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                    "
                    >Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" pa-3>
                  <v-btn
                    x-large
                    width="175"
                    color="primary"
                    style="
                      height: 40px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                    "
                    :disabled="!validateForm"
                    @click="createRequest"
                    :loading="loadingButton"
                    >Crear
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- End card wrapper -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      areaChosen: null,
      statusChosen: null,
      // store search input
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      dateStart: null,
      dateEnd: null,
      itemsResidente: [],
      itemsProyecto: [],
      itemsMateriales: [],
      itemsUnits: [],
      residente: "",
      proyecto: "",
      nombreMaterial: "",
      cantidadMaterial: "",
      unidadMedidaMaterial: "",
      unidadMedidaMaterialText: "",
      loadingButton: false,
      permisionUser: localStorage.getItem("permisionUser"),

      //request for materials
      selectRFM: {
        state: "Todas las solicitudes",
        value: 1,
      },
      itemRFM: [
        {
          state: "Todas los solicitudes",
          value: 1,
        },
        {
          state: "Mis solicitudes",
          value: 2,
        },
      ],
      aAllows: this.$store.state.aAllows.split(","),
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    //  this.$store.commit("setDebtDays", null);
  },
  watch: {
    statusChosen: function () {
      this.$store.commit("setStatus", this.statusChosen);
    },
    selectRFM: function () {
      this.$store.commit("setIMRFilter", this.selectRFM);
    },
    areaChosen: function () {
      this.$store.commit("setArea", this.areaChosen);
    },
    dateStart: function () {
      this.dateEnd = null;
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
  },
  computed: {
    validateForm() {
      return this.proyecto !== "" && this.itemsMateriales.length !== 0;
    },
    validateAnadir() {
      return (
        this.nombreMaterial !== "" &&
        this.cantidadMaterial !== "" &&
        this.unidadMedidaMaterial !== ""
      );
    },
  },
  mounted() {
    //this.getResidentes();
    this.getProyectos();
    this.getUnits();
  },
  methods: {
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 1 && evt.which < 46) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(cantidadMaterial) {
      if (cantidadMaterial !== "") {
        if (cantidadMaterial <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.cantidadMaterial = "";
        }
      }
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.itemsUnits.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    //Send new request
    createRequest() {
      this.loadingButton = true;

      const payload = {
        sProjectId: this.proyecto,
        aRequestMaterials: this.itemsMateriales.map((se) => {
          return {
            dQuantity: se.dQuantity,
            sMeasureUnitId: se.sMeasureUnitId,
            sName: se.sName,
          };
        }),
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };

      //post credentials and get access token from laravel backend
      db.post(`${uri}/api/v1/material_request`, payload, config)
        .then((response) => {
          this.dialog = false;
          this.itemsMateriales = [];
          this.proyecto = "";
          this.loadingButton = false;
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButton = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    deleteItemMaterial(e) {
      this.itemsMateriales.splice(e, 1);
    },
    setMaterial() {
      const arr = {
        idMaterial: this.itemsMateriales.length,
        sName: this.nombreMaterial,
        dQuantity: this.cantidadMaterial,
        sMeasureUnitId: this.unidadMedidaMaterial,
        unidadMedidaMaterialText: this.unidadMedidaMaterialText,
      };
      this.itemsMateriales.push(arr);
      this.clearItemsMateriales();
    },
    clearItemsMateriales() {
      this.nombreMaterial = "";
      this.cantidadMaterial = "";
      this.unidadMedidaMaterial = "";
    },
    getUnits() {
      // get units
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsUnits = resp.data.units;
          
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getResidentes() {
      // get residentes
      db.get(`${uri}/api/v1/users?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsResidente = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getProyectos() {
      // get proyectos
      db.get(`${uri}/api/v1/projects/?iPageNumber=1&iProjectFilter=2`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsProyecto = resp.data.projects;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style>
.v-select__slot {
  font-family: "Poppins", sans-serif;
   /* font-size: 12px !important; */
}
.v-input .v-input__slot {
  border-radius: 10px;
}
.v-input fieldset {
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  background-color: #fafafa;
}

.mdi-magnify {
  color: #242424 !important;
  font-size: 19px !important;
}

.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Raleway", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style
  scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #f4f4f4;
}
.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>